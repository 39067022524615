import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';

const Hotspots = (props) => {
    const hotspot1Position = { x: 800, y: 400 }; // Replace with your desired pixel position
    const hotspot2Position = { x: 750, y: 140 }; // Replace with your desired pixel position
    const hotspot3Position = { x: 1144, y: 390 }; // Replace with your desired pixel position
    const hotspot4Position = { x: 965, y: 624 }; // Replace with your desired pixel position
    const hotspot5Position = { x: 744, y: 664 }; // Replace with your desired pixel position
    const hotspot6Position = { x: 1240, y: 764 }; // Replace with your desired pixel position
    const [modalVisible, setModalVisible] = useState(false);
    const [modalImage, setModalImage] = useState('');
    const imageWidth = 1920; // Replace with your image's original width
    const imageHeight = 1080; // Replace with your image's original height

    const container = {
        hidden: { opacity: 0 },
        show: {
            // position: 'relative',
            // translate: 'transform(-50%, -50%)',
            // position: 'absolute', // 'relative
            top: 0, 
            left: 0,
            display: 'flex',
            width: '100vw',
            height: '100vh',
            opacity: 1,
            transition: {
                staggerChildren: 0.3,
            },
            // zIndex: 100,

        },
    };
    
    const item = {
        hidden: { 
            scale: 0,
            transition: { 
                type: 'spring', 
                stiffness: 500, 
                damping: 30,
            },
        },
        show: { 
            scale: 1, 
            transition: { 
                type: 'spring', 
                stiffness: 500, 
                damping: 30,
            },
        },
    };

    const handleButtonClick = (image) => {
        // console.log('handleButtonClick', image);
        setModalImage(image);
        setModalVisible(true);
    };

    const handleCloseModal = () => {
        setModalVisible(false);
    };

    const modalVariants = {
        hidden: { opacity: 0, scale: 0 },
        visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
        exit: { opacity: 0, scale: 0, transition: { duration: 0.5 } },
    };

    const backgroundVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 0.5 } },
        exit: { opacity: 0, transition: { duration: 0.5 } },
    };

    return (
        <>
        {/* {props.visible.thirdViewVisible ? ( */}
        <div style={{ 
            position: 'absolute', 
            width: '100vw', 
            height: '100vh', 
            top: 0, 
            left: 0,
            zIndex: 0,
        }}>

            {props.visible.thirdViewVisible ? (
            <AnimatePresence>
            {modalVisible && (
                <>
                    <motion.div 
                        style={{ 
                            position: 'fixed', 
                            top: 0, 
                            left: 0, 
                            width: '100vw', 
                            height: '100vh', 
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            zIndex: 199,
                        }}
                        variants={backgroundVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                    />
                    <motion.button 
                        initial={{ opacity: 0, scale: 0 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0 }}
                        className='close-button'
                        style={{ 
                            position: 'absolute', 
                            top: 48, 
                            right: 48,
                            width: '48px',
                            height: '48px',
                            borderRadius: '25%',
                            fontSize: '24px',
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                            blurfilter: 'blur(10px)',
                            border: 'none',
                            zIndex: 250,
                            backgroundColor: '#3CB4E590',
                            color: '#ffffff',
                        }} 
                        onClick={handleCloseModal}
                    >
                        X
                    </motion.button>
                    <motion.div 
                        style={{ 
                            position: 'fixed', 
                            top: 0, 
                            left: 0, 
                            width: '100vw', 
                            height: '100vh', 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center',
                            zIndex: 200,
                        }}
                        variants={modalVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        onClick={(e) => {
                            // Check if the click event's target is the outer div itself
                            if (e.target === e.currentTarget) {
                            handleCloseModal();
                            }
                        }}
                    >
                        
                    <img 
                        src={modalImage} 
                        alt="" 
                        style={{ 
                        maxWidth: '70%', 
                        maxHeight: '70%',
                        // boxShadow: '0 0 20px rgba(0, 0, 0, 0.8)',
                        }} 
                    />
                    
                    </motion.div>
                </>
                )}
            </AnimatePresence>
            ) : null}
            
            <motion.img
                initial={{ 
                    y: 0,
                 }}
                 animate={{
                    opacity: props.visible.thirdViewVisible ? 1 : 0,
                 }}
                 transition={{ duration: 1, ease: 'easeInOut' }}
                // animate={{ y: 0 }}
                // animate={{ y: secondViewVisible || thirdViewVisible ? '-200vh' : 0 }}
                // animate={{ y: props.visible.thirdViewVisible ? '-200vh' : '-200vh' }}
                // transition={{ duration: 1, ease: 'easeInOut' }}
                style={{ 
                    width: '100%', 
                    height: '100%', 
                    objectFit: 'cover', 
                }}
                src="/assets/siteplan_web.jpg"
                alt="siteplan_web"
            />
            {props.visible.thirdViewVisible ? (
            <motion.div 
                variants={container} 
                initial="hidden" 
                animate="show"
                transition={{ duration: 1, ease: 'easeInOut' }}
                // style={{position: 'absolute', zIndex: 101}}
                // style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            >
                {/* {console.log(`${hotspot1Position.y}`, imageHeight, imageHeight / 100)} */}
                <motion.button 
                    className='hotspot-button'
                    variants={item} 
                    style={{ 
                        position: 'absolute', 
                        // top: '50%', 
                        // left: '50%',
                        top: `${hotspot1Position.y / imageHeight * 100}%`, 
                        left: `${hotspot1Position.x / imageWidth * 100}%`,
                        zIndex: 100,
                    }} 
                    onClick={() => handleButtonClick('/assets/lobby_lounge.jpg')}>
                        LOBBY LOUNGE
                </motion.button>
                <motion.button 
                    className='hotspot-button'
                    // layout
                    variants={item} 
                    style={{ 
                        position: 'absolute', 
                        // top: '20%', 
                        // left: '70%',
                        top: `${hotspot2Position.y / imageHeight * 100}%`, 
                        left: `${hotspot2Position.x / imageWidth * 100}%` 
                    }} 
                    onClick={() => handleButtonClick('/assets/entry.jpg')}>
                        ENTRY
                </motion.button>
                {/* <motion.button 
                    className='hotspot-button'
                    variants={item} 
                    style={{ 
                        position: 'absolute', 
                        top: `${hotspot3Position.y / imageHeight * 100}%`, 
                        left: `${hotspot3Position.x / imageWidth * 100}%`
                    }} 
                    onClick={() => handleButtonClick('/assets/indigobay2.jpg')}>
                        SPA & WELLNESS
                </motion.button> */}
                <motion.button 
                    className='hotspot-button'
                    variants={item} 
                    style={{ 
                        position: 'absolute', 
                        top: `${hotspot4Position.y / imageHeight * 100}%`, 
                        left: `${hotspot4Position.x / imageWidth * 100}%`
                    }} 
                    onClick={() => handleButtonClick('/assets/pool_cabanas.jpg')}>
                        POOL CABANAS
                </motion.button>
                {/* <motion.button 
                    className='hotspot-button'
                    variants={item} 
                    style={{ 
                        position: 'absolute', 
                        top: `${hotspot5Position.y / imageHeight * 100}%`, 
                        left: `${hotspot5Position.x / imageWidth * 100}%`
                    }} 
                    onClick={() => handleButtonClick('/assets/pool_cabanas.jpg')}>
                        POOL BAR
                </motion.button> */}
                {/* <motion.button 
                    className='hotspot-button'
                    variants={item} 
                    style={{ 
                        position: 'absolute', 
                        top: `${hotspot6Position.y / imageHeight * 100}%`, 
                        left: `${hotspot6Position.x / imageWidth * 100}%`
                    }} 
                    onClick={() => handleButtonClick('/assets/indigobay.jpg')}>
                        RESTAURANT
                </motion.button> */}
            </motion.div>
            ) : null}
        </div>
        {/* // ) : null} */}
        </>
    );
}

export default Hotspots;