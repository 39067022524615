import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';

const Navigation = (props) => {

    const menuRef = useRef(null);
    const [footerVisible, setFooterVisible] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState(null);
    const [submenuStyle, setSubmenuStyle] = useState({});
    const [submenuOpen, setSubmenuOpen] = useState(false);

    const containerRef = useRef(null);
    const hotspot1Ref = useRef(null);
    const hotspot2Ref = useRef(null);
    const hotspot3Ref = useRef(null);

    // console.log('navigation props', props);

    useEffect(() => {
        if (props.visible.thirdViewVisible) {
            setFooterVisible(true);
        } else {
            setFooterVisible(false);
            setSelectedMenu(null);
        }
    }, [props.visible.thirdViewVisible]);

    useEffect(() => {
        const closeSubmenu = () => {
            setSubmenuOpen(false);
        };
        const handleResize = () => {
            if (containerRef.current && hotspot1Ref.current && hotspot2Ref.current && hotspot3Ref.current) {
                const containerWidth = containerRef.current.offsetWidth;
                const containerHeight = containerRef.current.offsetHeight;

                hotspot1Ref.current.style.top = `${containerHeight * 0.1}px`;
                hotspot1Ref.current.style.left = `${containerWidth * 0.2}px`;

                hotspot2Ref.current.style.top = `${containerHeight * 0.5}px`;
                hotspot2Ref.current.style.left = `${containerWidth * 0.5}px`;

                hotspot3Ref.current.style.top = `${containerHeight * 0.8}px`;
                hotspot3Ref.current.style.left = `${containerWidth * 0.7}px`;
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        document.addEventListener('click', closeSubmenu);

        return () => {
            document.removeEventListener('click', closeSubmenu);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleMenuClick = (menu, e) => {
        e.stopPropagation();
        setSelectedMenu(menu);
        setSubmenuOpen(true);

        if (menuRef.current) {
            const buttonWidth = menuRef.current.offsetWidth / ['OVERVIEW', 'NEIGHBOURHOOD', 'RENDERS', 'FLOOR PLANS', 'BUILDER STORY', 'TEAM', 'VIDEOS'].length;
            const leftPosition = e.target.offsetLeft + (e.target.offsetWidth - buttonWidth) / 2;
            setSubmenuStyle({
                width: `${buttonWidth}px`,
                left: `${leftPosition}px`,
            });
        }
    };

    const handleSubmenuClick = (e) => {
        e.stopPropagation();
        setSubmenuOpen(false);
    };


    return (
        <div>
            <motion.div
                className='menu'
                ref={menuRef}
                initial={{ y: '100%' }}
                animate={{ y: footerVisible ? 0 : '100%' }}
                transition={{ duration: 1, ease: 'easeInOut', type: 'spring'  }}
                exit={{ opacity: 0 }}
                style={{
                    position: 'fixed',
                    bottom: 0,
                    width: '100vw',
                    height: '64px',
                    backgroundColor: '#3CB4E5',
                    opacity: 0.8,
                    // backdropFilter: 'blur(10px)',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    color: selectedMenu === 'OVERVIEW' ? 'white' : 'yellow',
                    cursor: 'pointer',
                    zIndex: 15,
                    fontSize: '14px',
                    fontFamily: 'bold'
                }}
            >
                {['OVERVIEW', 'NEIGHBOURHOOD', 'RENDERS', 'FLOOR PLANS', 'BUILDER STORYS', 'TEAM', 'VIDEOS'].map((menu) => (
                    <div
                        key={menu}
                        className='menu-item'
                        onClick={(e) => handleMenuClick(menu, e)}
                        style={{
                            color: selectedMenu === menu ? 'yellow' : 'white',
                        }}
                    >
                        {menu}
                    </div>
                ))}
            </motion.div>

            <div style={{ overflow: 'hidden', height: submenuOpen ? 'calc(100vh - 64px)' : '0' }}>
                {/* {console.log('selectedMenu', selectedMenu)} */}
                {selectedMenu && submenuOpen && (
                    <motion.div
                        initial={{ y: 64, opacity: 0 }}
                        animate={{ y: 0, opacity: 0.8 }}
                        exit={{ y: '100%' }}
                        transition={{ duration: 0.3, ease: 'easeIn'}}
                        onClick={handleSubmenuClick}
                        style={{
                            // fontWeight: selectedMenu === 'OVERVIEW' ? 'bold' : 'normal',
                            // color: selectedMenu === 'OVERVIEW' ? 'white' : 'black',
                            position: 'fixed',
                            bottom: '64px',
                            ...submenuStyle,
                            backgroundColor: '#3CB4E5',
                            opacity: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            zIndex: 5,
                            fontSize: '14px',
                            fontFamily: 'bold',
                        }}
                    >
                        {['BROCHURE', '2D SITEPLAN'].map((submenu) => (
                            <div
                                key={submenu}
                                className="submenu-item"
                                style={{
                                    textAlign: 'center',
                                    width: '100%',
                                    padding: '10px 10px',
                                    fontWeight: 'bold',
                                    // color: 'white',
                                }}>
                                {submenu}
                            </div>
                        ))}
                    </motion.div>
                )}
            </div>
        </div>
    );
}

export default Navigation;